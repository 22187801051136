export default [
    {
        path: '/student/fee/option',
        name: 'student-fee-option',
        component: () => import('@/views/fee/StudentFeeOption'),
        meta: {
            action:'read',
            resource:'Payment Option',
            pageTitle: 'Student Fee Option',
            breadcrumb: [
                {
                    text: 'Student fee option',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/fee/head',
        name: 'student-fee-head',
        component: () => import('@/views/fee/StudentFeeHead'),
        meta: {
            action:'read',
            resource:'Fee Head',
            pageTitle: 'Student Fee Head',
            breadcrumb: [
                {
                    text: 'Student fee head',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/fee/type',
        name: 'student-fee-type',
        component: () => import('@/views/fee/StudentFeeType'),
        meta: {
            action:'read',
            resource:'Fee Type',
            pageTitle: 'Student Fee Type',
            breadcrumb: [
                {
                    text: 'Student fee types',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/assign/student/fee',
        name: 'assign-student-fee',
        component: () => import('@/views/fee/AssignedStudentFee'),
        meta: {
            action:'read',
            resource:'Assigned Fee',
            pageTitle: 'Assigned Student Fee',
            breadcrumb: [
                {
                    text: 'Assigned student fee',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/assign/student/canteen/bills',
        name: 'assign-canteen-bill',
        component: () => import('@/views/fee/AssignCanteenBill'),
        meta: {
            action:'read',
            resource:'Assign Canteen Bill',
            pageTitle: 'Assign Charge',
            breadcrumb: [
                {
                    text: 'Assign Canteen Bill',
                    active: true,
                },
            ],
        },
    },

]
